import { template as template_ef7ab90c5160405aa5cc96aedb3fbe0a } from "@ember/template-compiler";
import { concat } from "@ember/helper";
import concatClass from "discourse/helpers/concat-class";
const FKCol = template_ef7ab90c5160405aa5cc96aedb3fbe0a(`
  <div class={{concatClass "form-kit__col" (if @size (concat "--col-" @size))}}>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKCol;
