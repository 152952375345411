import { template as template_8edb2a219ddb4d1ba3aeff8b7961bbe5 } from "@ember/template-compiler";
const SidebarSectionMessage = template_8edb2a219ddb4d1ba3aeff8b7961bbe5(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
