import { template as template_065ab80d0eb647aa84302c52d8e0d99d } from "@ember/template-compiler";
const FKControlMenuContainer = template_065ab80d0eb647aa84302c52d8e0d99d(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
